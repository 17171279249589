<template> 
  <div id="packageCard" class="vx-row" @click="ViewPackageDetail">
    <div
      v-if="index % 2 == 0 ? (index / 2) % 2 == 0 : ((index - 1) / 2) % 2 == 0"
      :style="{ background: colorclass }"
      style="height: 15vw; width: 15vw;"
      class="p-5"
    >
      <img
        style="height: 5vw; width: 5vw; "
        :src="baseURL + iconImage"
      />
      <h4 style="color: white">{{ item.Name }}</h4>
      <div class="align-items-center" style="height: 3px; width: 40px; background: white; margin: 2px;"></div>
    </div>

    <img
      style="height: 15vw; width: 15vw"
      :src="baseURL + item.ImagePath"
      placeHolderType="package"
    />

    <div
      v-if="index % 2 == 0 ? (index / 2) % 2 != 0 : ((index - 1) / 2) % 2 != 0"
      :style="{ background: colorclass }"
      style="height: 15vw; width: 15vw;"
      class="p-5"
    >
      <img
        style="height: 5vw; width: 5vw;"
        :src="baseURL + iconImage"
      />
      <h4 style="color: white">{{ item.Name }}</h4>
      <div style="height: 3px; width: 40px; background: white; margin: 2px"></div>
    </div>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";
export default {
  components: {
    imageLazy,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    index: Number,
    colorclass: {
      type: String,
      default: "whitecolor",
    },
    iconImage:{
      type: String,
    }
  },
  data() {
    return {
      baseURL: domain,
    };
  },
  methods: {
    ViewPackageDetail() {
      debugger;
      if (!this.item.MedicalPackageCategoryID)
        this.$router.push("OurPackages/" + this.item.ID);
      else this.$emit("OpenPackage", this.item);
      //this.$router.push("/PackageDetail/"+ this.item.ID);
    },
  },
  created() {
    this.colorclass;
    debugger;
  },
};
</script>
<style>
#packageCard .vx-col {
  padding: unset;
}
#packageCard .vx-row {
  padding: unset;
  margin: unset;
}
</style>
