<template>
  <div id="MasterMedicalPackage">
    <img id="sliderImage" :class="windowWidth >= 1200? 'SliderStyle':'SliderStyleMobile'"  alt="" src="@/assets/images/Patient/packagebanner.jpg" />
   <div style="height:47vh" v-if="windowWidth >= 1200">
     </div>

    <div id="searchDev" :class="windowWidth >= 1200? 'searchPosition':''" >
        <div class="vx-row">
          <feather-icon
            v-if="EnglishLan"
            @click="back()"
            icon="ChevronLeftIcon"
            style="width: 30px; cursor: pointer"
          >
          </feather-icon>
          <feather-icon
            v-else
            @click="back()"
            icon="ChevronRightIcon"
            style="width: 30px; cursor: pointer"
          >
          </feather-icon>
          <p class="text-xl">
            {{ $t("OurPackages") }}
          </p>
        </div>

            <div class="search w-full mt-10">
              <div
                class="whiteBack searchCard"
                :class="[windowWidth > 500 ? 'searchCard' : 'searchCardMobil']"
              >
                <vs-row vs-type="flex" vs-w="12" style="margin-top: -30px">
                  <vs-col
                    vs-type="flex"
                    class=""
                    :class="[windowWidth > 500 ? '' : 'mt-3']"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="3.8"
                    vs-sm="12"
                  >
                    <feather-icon
                      icon="CommandIcon"
                      class="cursor-pointer m-1"
                    ></feather-icon>
                    <v-select
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      style="width: 80%; border: none !important"
                      id="specialyMobile"
                      label="Name"
                      v-model="search.MedicalPackageCategorID"
                      :options="medicalPackageCategories"
                      :placeholder="$t('PackageCategory')"
                      :reduce="(ID) => ID.ID"
                      @input="GetAlMedicalPackagesCountries"
                    />
                  </vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w=".2"
                    vs-sm="0"
                  >
                    <div class="divider"></div>
                  </vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="3"
                    vs-sm="12"
                  >
                    <feather-icon
                      icon="MapPinIcon"
                      class="cursor-pointer m-1"
                    ></feather-icon>
                    <v-select
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      style="width: 80%"
                      id="destinationMobile"
                      label="Name"
                      v-model="search.CountryID"
                      :options="countries"
                      :reduce="(ID) => ID.ID"
                      @input="updateData"
                      :placeholder="$t('Destination')"
                    />
                  </vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w=".2"
                    vs-sm="0"
                  >
                    <div class="divider"></div>
                  </vs-col>

                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="2.8"
                    vs-sm="12"
                  >
                    <feather-icon
                      icon="UsersIcon"
                      class="cursor-pointer m-1"
                    ></feather-icon>
                    <v-select
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      style="width: 80%"
                      @input="GetAllCategory"
                      label="Name"
                      v-model="search.HospitalID"
                      :options="hospitals"
                      :reduce="(ID) => ID.ID"
                      :placeholder="$t('Hospital')"
                    />
                  </vs-col>

                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    v-if="windowWidth >= 1200"
                    vs-w="2"
                    vs-sm="0"
                  >
                    <div
                      class="greenBackColor searchButton w-full"
                      @click="startSearchPackages"
                    >
                      <vs-row
                        vs-w="12"
                        class="mt-5"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <vs-col
                          vs-justify="flex-end"
                          vs-align="flex-start"
                          vs-w="4"
                        >
                          <h6 class="whitecolor text-bold">
                            {{ $t("Search") }}
                          </h6>
                        </vs-col>
                        <vs-col vs-justify="flex-end" vs-align="center" vs-w="5">
                          <feather-icon
                            style="padding-top: 10px"
                            class="whitecolor ml-5 mr-5"
                            icon="SearchIcon"
                          />
                        </vs-col>
                      </vs-row>
                    </div>
                  </vs-col>

                  <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-w="0"
                    v-if="windowWidth < 1200"
                    vs-sm="12"
                    vs-xs="12"
                  >
                    <div
                      class="greenBackColor w-full searchButtonMobil"

                    >
                      <vs-row
                        vs-w="12"
                        class="mt-2"
                        vs-justify="center"
                        vs-align="center"
                      >
                        <vs-col
                          vs-justify="flex-end"
                          vs-align="flex-start"
                          vs-w="3"
                        >
                          <h4 class="whitecolor text-bold" style="text-align: end;">
                            {{ $t("Search") }}
                          </h4>
                        </vs-col>
                        <vs-col vs-justify="flex-end" vs-align="center" vs-w="2">
                          <feather-icon
                            style="padding-top: 10px"
                            class="whitecolor"
                            icon="SearchIcon"
                          />
                        </vs-col>
                      </vs-row>
                    </div>
                  </vs-col>
                </vs-row>
              </div>
            </div>
     </div>

        <div   v-if="windowWidth >= 500" id="packages" style="width:65vw;margin:auto" class="vx-row mt-5">
            <div style="height: 15vw;" class="vx-col vx-col:w-1/2" v-for="(item,index) in medicalPackages" :key="index">
                  <package-card
                      :colorclass="item.BgColor"
                      :item="item"
                      :index="index"
                      :iconImage="item.MedicalPackageCategory.IconImagePath"
                      @OpenPackage="OpenPackage"
                    />
            </div>
        </div>

          <vs-row id="packages" class="mt-5 mb-5" vs-w="12" vs-justify="center"   v-if="windowWidth <= 500">
            <vs-col
              v-for="item in medicalPackages"
              :key="item.id"
              class="mt-2"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="4"
              vs-sm="12"
            >
              <package-card-one
                class="mt-5"
                :colorclass="item.BgColor"
                :data="item"
                 @OpenPackage="OpenPackage"
              />
            </vs-col>
        </vs-row>

        <PackageDetail v-if="selectedPackage.ID" id="PackageDetail"  :PackageID="selectedPackage.ID"  />

  </div>
</template>

<script>
import moduleMedicalPackage from "@/store/medicalPackage/moduleMedicalPackage.js";
import packageCard from "@/components/home/packageCardV2.vue";
import packageCardOne from "@/components/home/packageCard.vue";
import moduleMedicalPackageCategory from "@/store/settings/MedicalPackageCategory/moduleMedicalPackageCategory.js";
import homeCard from "../../../components/home/homeCard.vue";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleHospital from "@/store/hospital/moduleHospital.js";
import { domain } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";
import PackageDetail from "@/views/package/PackageDetail.vue"
export default {
  components: { packageCard, homeCard,imageLazy,PackageDetail,packageCardOne },

  data() {
    return {
      selectedPackage:{},
      EnglishLan: false,
        baseURL: domain,
      search: {
        MedicalPackageCategorID: "",
      },
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    hospitals() {
      debugger
      return this.$store.state.HospitalList.searchSimpleHospitals;
    },
    medicalPackageCategories() {
      debugger
      return this.$store.state.MedicalPackageCategoryList.MedicalPackageCategorys;
    },
    medicalPackages() {
      debugger;
      return this.$store.state.MedicalPackageList.medicalPackages;
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
  },
  methods: {
    GetAllCategory(){
      this.$store
      .dispatch("MedicalPackageCategoryList/SearchMedicalPackageCategories" , this.search)
      // this.GetAlMedicalPackagesCountries()
    },
    updateData(){
      this.$store
      .dispatch("MedicalPackageCategoryList/SearchMedicalPackageCategories" , this.search)
      this.$store.dispatch("HospitalList/SimpleSearchHospitals",this.search);
    },

    OpenPackage(packageModel)
    {
      debugger;
        // this.selectedPackage = packageModel;
        // setTimeout(function(){
        //   var elmnt = document.getElementById("PackageDetail");
        //   elmnt.scrollIntoView();
        // },100)
      //  this.$router.push(`/MedicalPackageList/GetMedicalPackage/${packageModel.ID}`);
      //   this.$store
      // .dispatch("MedicalPackageList/GetMedicalPackage" , packageModel.ID);
         this.$router.push({ name: 'PackageDetail', params: { PackageID: packageModel.ID } });
    },
    startSearchPackages() {
      this.selectedPackage = {}
      this.$store.dispatch(
        "MedicalPackageList/SearchMedicalPackage",
        this.search
      );
    },
    back() {
      this.$router.go(-1);
    },
    GetAlMedicalPackagesCountries(){
      this.$store.dispatch("CountryList/GetAlMedicalPackagesCountries",this.search);
    }
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;
    if (!moduleMedicalPackageCategory.isRegistered) {
      debugger;
      this.$store.registerModule(
        "MedicalPackageCategoryList",
        moduleMedicalPackageCategory
      );
      moduleMedicalPackageCategory.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      debugger;
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    if (!moduleMedicalPackage.isRegistered) {
      this.$store.registerModule("MedicalPackageList", moduleMedicalPackage);
      moduleMedicalPackage.isRegistered = true;
    }


  // if(!this.$store.state.CountryList.hospitalCountries||this.$store.state.CountryList.hospitalCountries.length==0)
  //   this.$store.dispatch("CountryList/GetAllHospitalCountries");

    // this.$store
    //   .dispatch("MedicalPackageCategoryList/GetAllMedicalPackageCategorys")
    //   .then((res) => {
    //     console.log(res);
    //   });
    this.selectedPackage = {}
this.updateData();
    debugger;
    if (this.$route.params.ID) {
      this.search.MedicalPackageCategorID = parseInt(this.$route.params.ID);
    }
    this.search.ValidDate = new Date();
    this.search.IsPublished = true;
    this.$store.dispatch(
      "MedicalPackageList/SearchMedicalPackage",
      this.search
    );
    this.GetAlMedicalPackagesCountries()
  },
  mounted(){
    debugger
    var sliderDev=document.getElementById("sliderImage");
    var searchDev =document.getElementById("searchDev");
    searchDev.style.top = sliderDev.clientHeight-(searchDev.clientHeight+50)+'px'

        window.onscroll = function() {myFunction()};

        function myFunction() {
           var navbar = document.getElementById("navbarStick");
        var sticky = navbar.offsetTop;
          if (window.pageYOffset >= sticky) {
            navbar.classList.add("sticky")
          } else {
            navbar.classList.remove("sticky");
          }
        }
  },
};
</script>
<style  >
.searchPosition
{
    position: absolute;
    /* top: 30vh; */
    z-index: 107;
    width: 73%;
}
#MasterMedicalPackage .SliderStyleMobile{
    width: 100%;
      z-index: 1;
}
#MasterMedicalPackage .SliderStyle{
  position: absolute;
      top: 0px;
    z-index: 90;
    left: 89px;
  width: 90vw;
 height:49vh;
 object-fit: cover;
}
</style>
<style>
#packages   .vx-col
{
  padding:unset
}
#packages   .vx-row
{
  padding:unset;
  margin:unset
}
#MedicalCategory .CardButton {
  border-radius: 32px 32px 32px 32px;
  margin-left: 2px;
  margin-right: 2px;
}
</style>


<style >
#specialyMobile .vs__dropdown-toggle {
  border-radius: unset;
}
#destinationMobile .vs__dropdown-toggle {
  border-radius: 18px;
}
</style>
<style scoped>
#MasterMedicalPackage .socialImage {
  width: 50px;
  height: 50px;
}
#MasterMedicalPackage .search {
  z-index: 238;
}
#MasterMedicalPackage .searchCard {
  margin: auto;
  height: flex;
  border-radius: 18px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000003d;

  border-radius: 24px;
  opacity: 1;
}
#MasterMedicalPackage .searchCardMobil {
  margin: auto;
  height: flex;
  border-radius: 18px;
}
#MasterMedicalPackage .searchButton {
  height: 80px;
  border-bottom-right-radius: 18px;
  border-top-right-radius: 18px;
}
#MasterMedicalPackage .searchButtonMobil {
  height: 50px;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
}
#MasterMedicalPackage .Hdivider {
  background: #00000029;
  width: 100%;
  height: 2px;
  margin-top: 5px;
}
#MasterMedicalPackage .divider {
  background: #00000029;
  width: 3px;
  height: 80px;
}
</style>

<style  lang="scss">
@import "@/assets/scss/vuexy/_variables.scss";

#MasterMedicalPackage .vs-inputx {
  background-color: $primary;
}
#MasterMedicalPackage .input-span-placeholder {
  color: white;
}
</style>
<style >
#MasterMedicalPackage .vue-flux {
  height: 80vh;
}
#MasterMedicalPackage .vs__dropdown-toggle {
  border: 0px solid #454a62 !important;
}
#MasterMedicalPackage .v-select .vs__dropdown-toggle .vs__search {
  font-size: 1.4rem;
}
#MasterMedicalPackage .vs__selected {
  white-space: nowrap;
  font-weight: bold !important;
  padding-top: 0.1rem;
  font-size: 15px;
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
